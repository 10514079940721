import type { ReactNode } from 'react'

import Head from 'next/head'

import { Facebook, Github, Patreon, YouTube } from '@nick-mazuk/ui/elements/icon'
import { Footer } from '@nick-mazuk/ui/layouts/footer'
import { FooterList } from '@nick-mazuk/ui/layouts/footer/footer-list'
import { Header } from '@nick-mazuk/ui/layouts/header'
import { Navbar } from '@nick-mazuk/ui/layouts/navbar'
import { NavbarLink } from '@nick-mazuk/ui/layouts/navbar/navbar-link'
import { NavbarMobileLink } from '@nick-mazuk/ui/layouts/navbar/navbar-mobile-link'

type Props = {
    children?: ReactNode | ReactNode[]
    title: string
}

const footerSocialIcons = [
    {
        icon: <Facebook />,
        name: 'Facebook',
        href: 'https://www.facebook.com/nicholas.mazuk.3',
    },
    {
        icon: <YouTube />,
        name: 'YouTube',
        href: 'https://www.youtube.com/FinaleSuperuser?sub_confirmation=1',
    },
    {
        icon: <Github />,
        name: 'Github',
        href: 'https://github.com/Nick-Mazuk',
    },
    {
        icon: <Patreon />,
        name: 'Patreon',
        href: 'https://www.patreon.com/finale_superuser',
    },
]

const projectsData = [
    {
        text: 'Parts and Score',
        path: 'https://partsandscore.com/',
    },
    {
        text: 'Finale Superuser',
        path: 'https://finalesuperuser.com/',
    },
    {
        text: 'JetStream',
        path: 'https://jetstreamfinale.com/',
    },
]

const resourcesData = [
    {
        text: 'JW Lua Scripts',
        path: '/resources/jw-lua',
    },
    {
        text: 'Keyboard Maestro Macros',
        path: '/resources/keyboard-maestro',
    },
    {
        text: 'Instrumental Practice Tracks',
        path: 'https://www.youtube.com/channel/UC3BXocrHcRtVUt2jUndInEA?sub_confirmation=1',
    },
]

const otherLinksData = [
    {
        text: 'Contact',
        path: '/contact',
    },
    {
        text: 'About',
        path: '/about',
    },
]

export const Layout = ({ children, title }: Props): JSX.Element => {
    return (
        <div>
            <Head>
                <title>{title === '' ? `Nick Mazuk` : `${title} | Nick Mazuk`}</title>
            </Head>
            <div className='flex flex-col justify-between min-h-screen antialiased text-gray-900 bg-white dark:text-gray-d900 dark:bg-black'>
                <Header sticky>
                    <Navbar
                        brand={{ content: 'Nick Mazuk' }}
                        right={
                            <>
                                <NavbarLink text='Music' path='/music' />
                                <NavbarLink text='Resources' path='/resources' />
                                <NavbarLink text='Blog' path='https://blog.nickmazuk.com' />
                                <NavbarLink text='About' path='/about' />
                            </>
                        }
                        mobile={
                            <>
                                <NavbarMobileLink text='Home' path='/' />
                                <NavbarMobileLink text='Music' path='/music' />
                                <NavbarMobileLink text='Resources' path='/resources' />
                                <NavbarMobileLink text='Blog' path='https://blog.nickmazuk.com' />
                                <NavbarMobileLink text='About' path='/about' />
                            </>
                        }
                    />
                </Header>
                <main id='content'>{children}</main>
                <Footer copyright='Nick Mazuk' icons={footerSocialIcons}>
                    <FooterList title='Projects' links={projectsData} />
                    <FooterList title='Resources' links={resourcesData} />
                    <FooterList title='Other' links={otherLinksData} />
                </Footer>
            </div>
        </div>
    )
}

export type Layout = ReturnType<typeof Layout>
